<script setup>

</script>

<template>
  <ModalPlugin v-if="open_modal">
    <template v-slot:header>
      <a href="" @click.prevent="open_modal = false">
        <i class="icon close"></i>
      </a>
      <div class="title-wrap">
        <strong class="title">Лидеры</strong>
      </div>
    </template>
    <template v-slot:body>
      <ul class="tab">
        <li v-for="(st, key) in states" :key="key" @click="state=key" :class="state===key?'active':''">{{ st }}</li>
      </ul>
      <div class="leaderboard full">
        <div class="leader-wrap">
          <ul class="list">
            <li v-for="item in rating" :key="item" :class="!item.rate?'no-rate':''">
              <div class="name">
                <strong v-if="user.id === item.id">{{ item.name }} (Вы)</strong>
                <span v-else>{{ item.name }}</span>
              </div>
              <div class="range">
                <div :class="'slide' + ((item.rate/(max_val||1)*100)<50?' red':'')" :style="`width: ${item.rate/(max_val||1)*100}%`"></div>
              </div>
              <div class="point">{{ item.rate }}</div>
            </li>
          </ul>
        </div>
      </div>

    </template>
    <template v-slot:bottom></template>
  </ModalPlugin>

  <template v-if="front">
        <h1 class="title">Лидерборд</h1>
    <br>
      <ul class="tab">
        <li v-for="(st, key) in states" :key="key" @click="state=key" :class="state===key?'active':''">{{ st }}</li>
      </ul>
      <div class="leaderboard full">
        <div class="leader-wrap">
          <ul class="list">
            <li v-for="item in rating" :key="item" :class="!item.rate?'no-rate':''">
              <div class="name">
                <strong v-if="user.id === item.id">{{ item.name }} (Вы)</strong>
                <span v-else>{{ item.name }}</span>
              </div>
              <div class="range">
                <div :class="'slide' + ((item.rate/(max_val||1)*100)<50?' red':'')" :style="`width: ${item.rate/(max_val||1)*100}%`"></div>
              </div>
              <div class="point">{{ item.rate }}</div>
            </li>
          </ul>
        </div>
      </div>
  </template>
  <div class="leaderboard" v-else>
    <div class="leader-wrap">
      <a href="" class="header" @click.prevent="open_modal=true">
        <div class="title">Лидеры</div>
        <i class="icon chevron"></i>
      </a>
      <ul class="list">
        <li v-for="item in rating" :key="item">
          <div class="name">
            <strong v-if="user.id === item.id">{{ item.name }} (Вы)</strong>
            <span v-else>{{ item.name }}</span>
          </div>
          <div class="range">
            <div class="slide" :style="`width: ${item.rate/(max_val||1)*100}%`"></div>
          </div>
          <div class="point">{{ item.rate }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {UserApi} from "@/api/user";
import ModalPlugin from "@/components/ModalPlugin.vue";

export default {
  props: {
    front: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state: 'offer',
      users: [],
      states: {offer: "Договоры", meet: "Встречи", cp: "КП"},
      status: 'small',
      open_modal: false
    }
  },
  components: {
    ModalPlugin
  },
  computed: {
    user() {
      return this.$store.getters.user || {}
    },
    rating() {
      let rate = this.users.map(x => {
        return {id: x.id, name: x.fullname, rate: x.audits[this.state]}
      })
      rate.sort(function (a, b) {
        return b.rate - a.rate
      });
      return rate
    },
    max_val() {
      return Math.max(...this.rating.map(x => x.rate))
    }
  },
  mounted() {
    UserApi.get_rating().then(response => this.users = response)
  }
}
</script>