import CheckIco from "@/components/CheckIco.vue";

let dataDetailMixin = {
    components: {CheckIco},
    computed: {
        statuses() {
            return this.$store.getters.statuses || {};
        },
        audit() {
            return this.$store.getters.audits?.filter(x => x.id === this.audit_id)[0]
        },
        audit_log() {
            let statuses = JSON.parse(JSON.stringify(this.statuses));
            let prev;
            Object.keys(statuses).forEach(status => {
                let log = this.audit.logs.filter(st => st.status === status)
                if (prev && !log.length) {
                    statuses[prev]["last"] = true
                    if (this.audit.rejected) {
                        statuses[prev]["state"] = true
                        prev = null;
                    }
                }
                if (log.length) {
                    statuses[status]["created_at"] = log[0].created_at
                    if (log[0].accept) statuses[status]["get_point"] = log[0].point
                    statuses[status]["accept"] = log[0].accept
                    prev = status
                }
            })
            return statuses
        }
    }

};
export default dataDetailMixin;
