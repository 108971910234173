import {HTTP} from "./common";

export const AuditApi = {
    update(object) {

        return HTTP.post("audits/", object).then((response) => {
            return response.data;
        });
    },
    list() {
        return HTTP.get(`audits/`).then((response) => {
            return response.data;
        });
    },
    statuses() {
        return HTTP.get(`audits/audit_statuses/`).then((response) => {
            return response.data;
        });
    },
    audit_dynamic(month, year) {
        return HTTP.get(`audits/audit_dynamic/?${year?'year='+year:''}${month?'&month='+month:''}`).then((response) => {
            return response.data;
        });
    },
    audit_contracts(month, year) {
        return HTTP.get(`audits/audit_contracts/?${year?'year='+year:''}${month?'&month='+month:''}`).then((response) => {
            return response.data;
        });
    },
};
