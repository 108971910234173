import {HTTP} from "./common";

export const UserApi = {
    change_password(data) {
        return HTTP.post("/auth/change_password/", data);
    },
    restore_password(email) {
        return HTTP.post('/auth/restore_password/', {email: email})
    },

    get_rating(data) {
        return HTTP.get("/users/get_rating/", data).then((response) => {
            return response.data;
        });
    },
    check_token(uid, token) {
        return HTTP.post('/auth/check_token/', {uid: uid, token: token})
    },
};
