<script setup>

</script>

<template>
  <div class="not-found">
    <div class="title">
      <h1>404</h1>
      <span>Страница не найдена</span>
      </div>
  </div>
</template>

<style scoped>

</style>