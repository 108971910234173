import axios from "axios";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.baseURL = process.env.VUE_APP_DEV_MODE?process.env.VUE_APP_ROOT_API:`${location.protocol}//${location.host}/api/v1/`
axios.defaults.withCredentials = true
import {useCookies} from "vue3-cookies";

const { cookies } = useCookies();

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_DEV_MODE?process.env.VUE_APP_ROOT_API:`${location.protocol}//${location.host}/api/v1/`,
  headers: {
    // Set your Authorization to 'JWT', not Bearer!!!
    Authorization: `JWT ${ cookies.get("token") || null}`,
    "Content-Type": "application/json",
  },
  xhrFields: {
    withCredentials: true,
  },
});
HTTP.interceptors.request.use(
  function (config) {

    // store.dispatch("loader", true);
    return config;
  },
  function (error) {
    // store.dispatch("loader", false);
    return Promise.reject(error);
  }
);
HTTP.interceptors.response.use(
  function (response) {

    // store.dispatch("loader", false);
    return response;
  },
  function (error) {
    // if (error.response.status === 404) {
    //   store.dispatch("go_404");
    // }
    // if (error.response.status === 403) {
    //   alert("Действие запрещено")
    // }
    // if (error.response.status === 401) store.dispatch("logout");
    // // store.dispatch("loader", false);
    return Promise.reject(error);
  }
);
export default HTTP;
