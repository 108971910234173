<script setup>
import {onMounted, onUnmounted} from "vue";

onMounted(() => {
  document.body.classList.add('full-width')
})
onUnmounted(() => {
  document.body.classList.remove('full-width')
})
</script>

<template>
  <div class="h90 d-flex">
    <div class="login">
      <div class="top">
        <img alt="Logo" title="Logo" class="logo-bank big" :src="logo"/>
      </div>
      <form class="form form-signin">
        <div class="form-item">
          <label for="id_email">Логин <span class="req">*</span></label>
          <input @keyup="removeError(user)" name="email" id="id_email" :class="user.username_error?'error':''"
                 v-model="user.username" required type="email" placeholder="email">
        </div>

        <div class="form-item">
          <label for="id_password">Пароль <span class="req">*</span></label>
          <input @keyup="removeError(user)" name="password" id="id_password" :class="user.password_error?'error':''"
                 v-model="user.password" required :type="show_pass?'text':'password'"
                 placeholder="Пароль">
          <i v-on:click="show_pass=!show_pass" :class="`icon ${show_pass?' eye-bold':'eye'}`"></i>
        </div>

        <div class="form-item">
          <button class="btn" :disabled="!(user.username&&user.password)" @click="login">Войти</button>
        </div>
      </form>
      <div class="action">
        <div class="value">Забыли пароль?</div>
        <router-link :to="{name: 'restore_password'}">ЗАПРОСИТЬ</router-link>

      </div>
      <div class="bottom">
        <div class="text-wrapper-2">Powered by</div>
        <div class="logo-dr"><img alt="Logo DR" class="img" :src="publicPath+'LogoDr.svg'"/></div>
      </div>
    </div>
  </div>
</template>
<script>

import dataMixin from './mixins.js';

export default {

  data() {
    return {
      user: {
        username: "",
        password: ""
      },
      show_pass: false,
      validate: {
        username: {
          func: (o) => {
            return !o.username.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
          }, error: 'Неверный формат email адреса'
        },
        password: {},
      }
    }
  },
  mixins: [dataMixin],
  computed: {
    is_auth() {
      return this.$store.getters.jwt;
    },
  },
  watch: {
    is_auth(val) {
      if (val) this.$router.push('/');
    }
  },
  mounted: function () {
    // if (location.host !== process.env.VUE_APP_BASE_DOMAIN) window.location.href = `${location.protocol}//${process.env.VUE_APP_BASE_DOMAIN}`
    if (this.is_auth) this.$router.push('/');
  },
  methods: {
    login: function (e) {
      e.preventDefault();
      let $this = this;
      if (!this.validateData(this.user)) {
        this.$store.dispatch('login', this.user).then(() => {
          // setTimeout(function () {
            location.pathname = '/';
          // }, 300);
        }).catch(() => {
          $this.$notify({
            type: 'error',
            title: "Ошибка",
            text: 'Данные неверны'
          })
        })
      }
    }
  }
}
</script>