import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import Notifications from '@kyvg/vue3-notification'
import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'
import VueCookies from 'vue3-cookies'
import './registerServiceWorker'

const vfm = createVfm()
let app = createApp(App).use(router).use(store).use(Notifications).use(VueCookies).use(vfm)
app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: `.${process.env.VUE_APP_BASE_DOMAIN}`,
    secure: true,
    sameSite: "None"
});


app.mount('#app')