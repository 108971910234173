<script setup>
const services = [
  {name: "Рестораны Novikov Group", price: 300, ico: "food"},
  {name: "Авиабилеты", price: 400, ico: "fly"},
  {name: "Билеты на мероприятия", price: 150, ico: "theater"},
]
</script>

<template>
  <div class="text">
    <template v-if="$store.getters.user.point">
      <h2>На что потратить <br>
        <span class="border-bottom">{{ $store.getters.user.point }}</span> баллов?</h2>
      <p>
        <strong>Как это работает:</strong><br>
        1. Вы оставляете заявку на выбранную услугу.<br>
        2. С вами связывается наш менеджер.<br>
        3. Мы направляем вам сертификат в ресторан или билеты.<br>
      </p>
    </template>
    <template v-else>
      <h2>Пока у вас нет баллов</h2>
      <p>
        <strong>Где взять баллы</strong><br>
        Создавайте заявки на аудит и мы начислим вам баллы за каждый отправленный аудит, назначенную встречу и
        заключенный контракт.
      </p>
    </template>
    <ul class="services">
      <li v-for="service in services" :key="service.price"
          :class="$store.getters.user.point >= service.price?'active':''">
        <i :class="'icon '+service.ico"></i>
        <div class="item">
          <div class="name">{{ service.name }}</div>
          <div class="price">
            <span>от {{ service.price }}</span>
            <i v-if="$store.getters.user.point >= service.price" class="icon chevron d-block"></i>
            <i v-else class="icon lock d-block"></i>
          </div>
        </div>
      </li>
    </ul>
    <p v-if="$store.getters.user.point">
      <strong>Не хватает баллов?</strong><br>
      Создавайте заявки на аудит и мы начислим вам баллы за каждый отправленный аудит, назначенную встречу и заключенный
      контракт.
    </p>


  </div>
</template>
