<template>
  <div class="chart">
    <h1>Заключение контрактов</h1>
    <MonthRange :setValue="setValue" :range_checker="true"></MonthRange>

    <div style="height: 250px; width: 100%;" v-if="data">
      <div id="legend-container" class="legend"></div>
      <div class="stat">
        <strong>{{ data.data.reduce((a, c) => a + (typeof(c) === "number"?c:0), 0) }} в апр 2024</strong>
        <strong>{{ Number((data.average).toFixed(1)) }} в год</strong>
      </div>
      <Line
          id="my-chart-id"
          v-if="chartData"
          :options="chartOptions"
          :data="chartData"
          :plugins="[htmlLegendPlugin]"
      />
    </div>

  </div>
</template>


<script>
import {Line} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement} from 'chart.js'
import {AuditApi} from "@/api/banker";
import htmlLegendPlugin from "@/components/legendPlugin";
import MonthRange from "@/components/MonthRange.vue";

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

ChartJS.defaults.plugins.legend.display = false;


export default {
  components: {
    Line, MonthRange
  },
  data() {
    return {
      date: {},
      htmlLegendPlugin: htmlLegendPlugin,
      data: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x:
              {
                ticks: {
                  // callback: function(value, index, ticks) {
                  //       console.log(value, index, ticks)
                  //       return value
                  //   },
                  font: {
                    size: 9,
                  }
                },
              },
          y:
              {
                ticks: {
                  display: true,
                  stepSize: 2,
                },
              },

        },
        plugins: {
          htmlLegend: {
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          }
        }
      }
    }
  },
  mounted() {
    this.getData(new Date().getMonth() + 1)
  },
  watch:{
    date(){
      this.getData()
    }
  },
  methods: {
    getData(month) {
      AuditApi.audit_contracts(this.date.month||month, this.date.year||"").then(response => this.data = response)
    },
    setValue(val){
      this.date = val
    }
  },
  computed: {
    chartData() {
      if (this.data) {
        return {
          labels: this.data.labels,
          datasets: [{
            label: "Контракты",
            backgroundColor: "#29AC50",
            borderColor: "#29AC50",
            lineTension: 0.3,
            borderWidth: 4,
            pointRadius: 1,
            fill: false,
            data: this.data.data
          },
            {
              label: "Среднее по году",
              backgroundColor: "#363758",
              borderColor: "#363758",
              borderDash: [10, 5],
              lineTension: 0.3,
              borderWidth: 1,
              pointRadius: 1,
              fill: false,
              data: this.data.data.map(() => this.data.average)
            }]
        }
      }
      return null
    }
  }

}
</script>