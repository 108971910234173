import Vuex from "vuex"
import router from './router/index'
import HTTP from './api/common'
import axios from 'axios'
import {useCookies} from "vue3-cookies";

const BASE_DOMAIN = process.env.VUE_APP_BASE_DOMAIN
const COOKIES_BASE_DOMAIN = process.env.NODE_ENV === "development"?"localhost":location.host


const STATUS_SETTINGS = {
    order: {
        name: "Аудит заказан",
        end: "Аудит заказан",
        wait: true,
        error: "Аудит отменён",
    },
    create: {
        name: "Аудит создан",
        end: "Аудит создан",
        wait: true,
        error: "Аудит отменён"
    },
    client: {
        name: "Отправить аудит клиенту",
        end: "Аудит отправлен клиенту",
        error: "Аудит не отправлен клиенту",
        active: true
    },
    meet: {
        name: "Назначить встречу",
        end: "Встреча назначена",
        error: "Встреча не назначена",
        active: true
    },
    cp: {
        name: "Готовим коммерческое предложение",
        end: "Коммерческое предложение готово",
        error: "Встреча отменена",
        wait: true
    },
    deal: {
        name: "Заключить сделку",
        end: "Сделка заключена",
        error: "Cделка не заключена",
        active: true
    },
    end: {
        name: "Сделка заключена",
        end: "Сделка заключена",
        hide: true
    }
};
const {cookies} = useCookies();

export default new Vuex.Store({
    state: {
        user: '{}',
        route: null,
        is_auth: localStorage.getItem("is_auth") || false,
        jwt: cookies.get("token") || null,
        show_pwa: localStorage.getItem("pwa") || false,
        load: false,
        audits: [],
        organization: [],
        statuses: {}
    },
    mutations: {
        set_route(state, route) {
            state.route = route;
        },
        set_audits(state, audits) {
            state.audits = audits;
        },
        set_statuses(state, statuses) {
            let status = JSON.parse(JSON.stringify(STATUS_SETTINGS))
            statuses.forEach(x => status[x.name]["point"] = x.point)
            state.statuses = status;
        },
        updateObject(state, object) {
            let index = state.audits.findIndex(
                x => x.id === object.id
            );
            if (index + 1) {
                let state_obj = state.audits[index]
                state_obj = {...state_obj, ...object}
                state.audits.splice(index, 1, state_obj);

            } else state.audits.push(object);
        },
        auth_success(state, user) {
            state.user = user;
            state.is_auth = true;
            localStorage.setItem("is_auth", '1');
        },

        updateToken(state, token) {
            cookies.set("token", token, '30D', "/", COOKIES_BASE_DOMAIN);
            state.jwt = token;
        },
        addOrgs(state, orgs) {
            state.organization = orgs;
        },

        logout(state) {
            state.user = {};
            localStorage.removeItem("is_auth");
            cookies.remove("token", "/", COOKIES_BASE_DOMAIN);
            state.is_auth = false;
            state.jwt = null;
        },
        loader(state, status) {
            state.load = status;
        },
    },
    actions: {
        updateObject({commit}, objectData) {
            commit("updateObject", objectData);
        },
        init({commit, dispatch, state}, path) {

            if (!state.organization.length)HTTP.get('/auth/organization/').then((response) => {
                    commit("addOrgs", response.data);
                })
            if ((!path || path !== 'login')) {
                return Promise.all([
                    dispatch('refresh_token'),
                    dispatch('get_user')
                ])
            }
            return true
        },
        login({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/obtain_token/', user).then((response) => {
                    commit("updateToken", response.data.token);
                    dispatch('get_user');
                    resolve(response);
                })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },

        refresh_token({commit, dispatch}) {
            let $this = this;
            if ($this.getters.refresh) return new Promise((resolve, reject) => {
                HTTP.post('/auth/refresh_jwt_token/', {token: $this.getters.jwt}).then((response) => {
                    commit("updateToken", response.data.token);
                    resolve(response);
                }).catch((error) => {
                    if (error.response.status === 401) dispatch("logout")
                    reject(error)
                })
            })
        },

        get_user({commit, dispatch}) {
            let $this = this;
            return new Promise((resolve) => {
                const base = {
                    baseURL: process.env.VUE_APP_DEV_MODE?process.env.VUE_APP_ROOT_API:`${location.protocol}//${location.host}/api/v1/`,
                    headers: {
                        Authorization: `JWT ${$this.getters.jwt}`,
                        "Content-Type": "application/json",
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                };

                const axiosInstance = axios.create(base);
                axiosInstance({
                    url: "/users/get_info/",
                    method: "get",
                    params: {},
                }).then((response) => {
                    localStorage.setItem("get_user", JSON.stringify(response.data));
                    if (response.data.organisation?.domain && response.data.organisation.domain !== location.host && !process.env.VUE_APP_DEV_MODE){
                        commit('logout');
                    }
                    commit('auth_success', response.data);
                    resolve(response);
                }).catch((error) => {
                    if (error.response.status === 401) dispatch('logout')
                });
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                HTTP.get('/auth/logout/')
                    .then(() => {
                        commit('logout');
                        router.push({name: 'login', query: {'next': this.getters.getRoute.path}}).then(() => resolve());
                    })
            })
        },
        loader({commit}, status) {
            commit("loader", status);
        },
    },
    getters: {
        isLoggedIn: (state) => state.is_auth,
        user: (state) => state.user,
        jwt: (state) => state.jwt,
        getRoute: (state) => state.route,
        show_pwa: (state) => state.show_pwa,
        load: (state) => state.load,
        organization: (state) => state.organization,
        template: (state) => {
            const domain = state.user.organisation?.domain || location.host
            let base = state.organization.filter(x=>x.domain===domain)
            if (base){
                return base[0]
            }
        },
        user_domain: (state) => state.user.organisation?.domain || BASE_DOMAIN,
        audits: (state) => state.audits,
        statuses: (state) => state.statuses,
    },
});