<script setup>
import AuditItem from "@/components/AuditItem.vue";
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

const store = useStore()

const statuses = [
  {name: "Все", value: "", fullname: ""},
  {name: "Аудиты", value: "create", fullname: "Аудит не отправлен"},
  {name: "Встречи", value: "meet", fullname: "Встреча не назначена"},
  {name: "КП", value: "cp", fullname: "Договор не заключен"},
]
const status_name = ref("")
const user = ref(null)

const audits = computed(() => {
  return store.getters.audits
})
const filter_audits = computed(() => {
  let user_audits = audits.value.filter(x => {
    return status_name.value ? x.status === status_name.value : x
  })
  if (user.value) user_audits = user_audits.filter(x => x.user_id === user.value)
  return user_audits
})

const users = computed(() => {
  return audits.value.map(x => {
    return {id: x.user_id, name: x.user_name}
  }).filter((value, index, self) => index === self.findIndex(t => t.id === value.id))
})


</script>

<template>
  <ul class="tab">
    <li v-for="status in statuses" @click="status_name=status.value" :key="status.value"
        :class="status.value===status_name?'active':''">
      {{ status.name }}
    </li>
  </ul>
  <div class="filter" v-if="$store.getters.user.is_curator">
    <div class="form">
      <div class="form-item select">
        <label for="is_user">Консультант</label>
        <select v-model="user" name="" id="is_user">
          <option value=""></option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div class="status" v-for="status in statuses" :key="status.value">
    <template v-if="status.value && filter_audits.filter(x=>x.status === status.value).length">
      <h1>{{ status.fullname }}</h1>
      <div class="audit-list archive">
        <AuditItem v-for="audit in filter_audits.filter(x=>x.status === status.value)" :key="audit.id"
                   :audit="audit"></AuditItem>
      </div>
    </template>

  </div>
</template>

<style scoped>

</style>