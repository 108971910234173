import {createRouter, createWebHistory} from 'vue-router'
import store from '../store';

import FrontView from '@/views/FrontView.vue'
import LoginView from '@/views/LoginView.vue'
import RestorePass from '@/views/RestorePass.vue'
import PointView from "@/views/PointView.vue";
import ArchiveView from "@/views/ArchiveView.vue";
import RuleView from "@/views/RuleView.vue";
import ClientView from "@/views/ClientView.vue";
import AuditsView from "@/views/AuditsView.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import SetPassword from "@/views/SetPassword.vue";


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            meta: {
                // notAuth: true
            }
        },
        {
            path: '/password',
            name: 'restore_password',
            component: RestorePass,
            meta: {
                notAuth: true
            }
        },

        {
            path: '/set_password/:uid/:token',
            name: 'set_password',
            component: SetPassword,
            meta: {
                notAuth: true
            }
        },

        {
            path: '/',
            component: FrontView,
            name: 'front',
            meta: {
                title: 'Заявки',
                requiresAuth: true
            }
        },
        {
            path: '/points',
            component: PointView,
            name: 'point',
            meta: {
                title: 'Баллы',
                requiresAuth: true
            }
        },
        {
            path: '/archive',
            component: ArchiveView,
            name: 'archive',
            meta: {
                title: 'Архив',
                requiresAuth: true
            }
        },
        {
            path: '/rule',
            component: RuleView,
            name: 'rule',
            meta: {
                title: 'Правила',
                requiresAuth: true
            }
        },
        {
            path: '/clients',
            name: 'clients',
            component: ClientView,
            meta: {
                title: 'Клиент',
                requiresAuth: true
            }
        },
        {
            path: '/audits',
            name: 'audit',
            component: AuditsView,
            meta: {
                title: 'Аудиты',
                requiresAuth: true
            }
        },
        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: PageNotFound,
            meta: {
                title: 'Страница не найдена'
            }
        }
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});
//
export default router
const DEFAULT_TITLE = 'DR Partner';

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || DEFAULT_TITLE;
    next();
});
router.beforeEach((to, from, next) => {
    store.commit('set_route', to);
    store.dispatch('init', (to.meta.notAuth ? 'login' : to.name)).then(() => {

        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (store.getters.isLoggedIn) {
                next();
                return
            }
            next(`/login/?next=${to.fullPath}`);
        } else if (to.matched.some(record => record.meta.notAuth)) {
            if (store.getters.isLoggedIn) {
                next('/')
            } else {
                next();
            }

        } else {
            next()
        }

    });
});
