<script setup>

import AuditDetail from "@/components/AuditDetail.vue";
</script>

<template>
  <ModalPlugin v-if="open_modal" :show_bottom="false">
    <template v-slot:header>
      <div class="toolbar">
        <a href="" @click.prevent="open_modal=false" class="cancel">Отменить</a>
        <span class="title">Новый аудит</span>
        <a href="" @click.prevent="submitAudit" :class="'send' + (!(audit.name && audit.info)?' disabled':'') ">Отправить</a>
      </div>
    </template>
    <template v-slot:body>
      <form class="form">
          <div class="form-item">
            <label for="id_name">ФИО <span class="req">*</span></label>
            <input
                id="id_name"
                @keyup="removeError(audit)" :class="audit.name_error?'error':''"
                placeholder="Фамилия Имя Отчество"
                v-model="audit.name" required/>
            <div class="help-text">Например, Иванов Иван Иванович</div>
          </div>
          <div class="form-item">
            <div class="warning-info">
              Обязательно заполните хотя бы одно поле информации о клиенте, чтобы могли его быстро найти:
            </div>
          </div>
          <div class="form-item">
            <label for="id_info">Информация о клиенте <span class="req">*</span></label>
            <textarea
                id="id_info"
                @keyup="removeError(audit)" :class="audit.info_error?'error':''"
                v-model="audit.info" required
                placeholder="Дата рождения, в какой компании работает, какая должность…"></textarea>
          </div>
        <div class="form-item-group">
          <div class="form-item checkbox">

            <input v-model="audit.accept" id="id_accept" type="checkbox">
            <label for="id_accept">Клиент согласовал аудит</label>

          </div>
          <div class="form-item checkbox">
            <input v-model="audit.has_contact" id="id_contact" type="checkbox">
            <label for="id_contact">Есть прямой контакт с клиентом, могу организовать встречу/ звонок</label>

          </div>
          </div>
          <div class="form-item">
            <button :disabled="!audit.name && !audit.info" @click.prevent="submitAudit">Отправить заявку</button>
          </div>
        </form>
    </template>
    <template v-slot:bottom>

    </template>

  </ModalPlugin>
  <AuditDetail v-if="audit_object&&open_audit" :open="()=>open_audit=false" :audit_id="audit_object.id"
               :statuses="statuses"></AuditDetail>
  <button v-if="!$store.getters.user.is_curator" class="w100" @click="open_modal=true">
    <i class="icon add d-block"></i>
    Новый аудит
  </button>
</template>

<script>
import dataMixin from '../views/mixins.js';
import detailMixins from "@/components/detailMixins";
import {AuditApi} from "@/api/banker";
import ModalPlugin from "@/components/ModalPlugin.vue";

export default {
  data() {
    return {
      open_modal: false,
      open_audit: true,
      statuses: [],
      audit: {
        name: "", info: "", has_contact: false, accept: false
      },
      validate: {
        name: {},
        info: {},
      }
    }
  },
  mixins: [dataMixin, detailMixins],
  components: {
    ModalPlugin
  },
  computed: {
    audit_object() {
      return this.audit.id ? this.$store.getters.audits.find(x => x.id === this.audit.id) : null
    },
  },
  methods: {
    submitAudit: function () {
      let $this = this;
      if (!this.validateData(this.audit)) {
        AuditApi.update($this.audit).then(response => {
          $this.audit = response
          $this.open_modal = false
          $this.$store.dispatch('updateObject', response)
          $this.$notify({
            type: 'success',
            title: "Успешно!",
            text: 'Заявка принята'
          })
        })
      }
    }
  }
}
</script>