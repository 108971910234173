<template>
  <div :class="`container${$store.getters.user.is_curator?' curator':''}`">
    <notifications/>
    <div class="user-info" v-if="is_auth">
      <img alt="Logo" title="Logo" class="logo-bank"
           :src="logo"/>
      <!--          <img alt="Logo" title="Logo" class="logo-bank" :src="$store.getters.user.organisation.logo"/>-->
      <div class="user">
        <div class="name">{{ $store.getters.user.fullname }}</div>
        <a href="" @click.prevent="logout">
          <i class="icon exit"></i>
        </a>
      </div>
    </div>
    <router-view/>
  </div>
  <TabBar v-if="is_auth"></TabBar>

</template>

<script>
import('@/assets/scss/styleguide.scss')
import(`@/assets/scss/base.scss`)
import TabBar from "@/components/TabBar.vue";
import {AuditApi} from "@/api/banker";


export default {
  name: 'App',
  components: {
    TabBar
  },
  data() {
    return {
      chatSocket: null,
      publicPath: process.env.BASE_URL,
      root: null
    }
  },
  computed: {
    is_auth() {
      return this.$store.getters.isLoggedIn && this.$route.name !== 'login'
    },
    user() {
      return this.$store.getters.user
    },
    logo() {
      return this.$store.getters.template?.logo || this.publicPath + 'dr.png'
    },
    style() {
      return this.$store.getters.template?.style
    },
    color100() {
      return this.$store.getters.template?.color100
    },
    color80() {
      return this.$store.getters.template?.color80
    }
  },
  watch: {
    user(val) {
      if (val && val.id && !this.chatSocket) this.connectWs()
    },
    style(css) {
      let head = document.head || document.getElementsByTagName('head')[0],
          style = document.createElement('style');
      head.appendChild(style);

      style.appendChild(document.createTextNode(css));
    },
    color100: {
      handler: function () {
        this.$nextTick(() => {
          this.root.style.setProperty("--brand-100", this.color100);
        })
      },
      immediate: true
    },
    color80: {
      handler: function () {
        this.$nextTick(() => {
          this.root.style.setProperty("--brand-80", this.color80);
        })
      },
      immediate: true

    }
  },
  mounted() {
    this.root = document.documentElement;
  },
  created: function () {
    if (this.$store.getters.jwt) {
      AuditApi.list().then(response => this.$store.commit('set_audits', response.results))
      AuditApi.statuses().then(response => this.$store.commit('set_statuses', response))
    }
    let $this = this;
    setInterval(function () {
      $this.$store.dispatch('refresh_token');
    }, 1000 * 60 * 5);
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {

            this.$router.push('/login')
          })
    },
    connectWs() {
      let $this = this,
      ws_url = process.env.VUE_APP_DEV_MODE?process.env.VUE_APP_WEBSOCKET_URL:`${location.protocol.endsWith('s:')?'wss':'ws'}://${location.host}`;
      this.chatSocket = new WebSocket(
          `${ws_url}/ws/response_channel/${$this.user.id}/`
      );

      this.chatSocket.onmessage = function (e) {
        let data = JSON.parse(JSON.parse(e.data))
        if (data.object) {

          $this.$store.dispatch('updateObject', data.object);
          if (data.message) $this.$notify({
            group: 'alert',
            type: 'success',
            text: data.message
          });
        }
      };

      this.chatSocket.onclose = function () {
        console.error(`Chat socket closed unexpectedly`);
        setTimeout(function () {
          if ($this.user.id) $this.connectWs();
        }, 1000);
      };
      this.chatSocket.onerror = function (err) {
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        this.chatSocket.close();
      };
    }
  },
}
</script>

