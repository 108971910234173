<script setup>

</script>

<template>

  <ul class="tab">
    <li @click="type='end'" :class="type==='end'?'active':''">Успешные</li>
    <li @click="type='rejected'" :class="type!=='end'?'active':''">Отклонённые</li>
  </ul>
  <div class="filter" v-if="$store.getters.user.is_curator">
    <div class="form">
      <div class="form-item select">
        <label for="is_user">Консультант</label>
        <select v-model="user" name="" id="is_user">
          <option value=""></option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="audit-list archive">
    <AuditItem v-for="audit in archive_audits" :key="audit.id" :audit="audit"></AuditItem>
  </div>

</template>


<script>
import AuditItem from "@/components/AuditItem.vue";


export default {
  name: 'FrontView',
  components: {
    AuditItem
  },
  data() {
    return {
      type: "end",
      user: null
    }
  },
  computed: {
    audits() {
      return this.$store.getters.audits
    },
    archive_audits() {
      let audits = this.type === "end" ? this.audits.filter(x => x.status === this.type) : this.audits.filter(x => x.rejected)
      if (this.user) audits = audits.filter(x => x.user_id === this.user)
      return audits
    },
    users() {
      return this.audits.map(x => {
        return {id: x.user_id, name: x.user_name}
      }).filter((value, index, self) => index === self.findIndex(t => t.id === value.id))
    }
  }

}
</script>
