<script setup>

</script>

<template>
  <div class="audit">
    <div class="point-info">
      <template v-if="!$store.getters.user.is_curator">
        <div>
          <span>Вы накопили</span>
          <div class="point">{{ $store.getters.user.point || 0 }} баллов</div>
        </div>
        <router-link to="/points" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">Потратить баллы</button>
        </router-link>
      </template>
    </div>
    <LeaderBoard :front="$store.getters.user.is_curator"></LeaderBoard>

    <div class="audit-list" v-if="!$store.getters.user.is_curator">
      <ul>
        <AuditStatus v-for="(status, key, index) in $store.getters.statuses"
                     :key="key"
                     :status_type="key"
                     :status="status"
                     :audits="audits"
                     :order="index"></AuditStatus>
      </ul>
    </div>
    <div class="chart-wrap" v-else>
      <ChartDynamic></ChartDynamic>
      <ChartContract></ChartContract>
    </div>

  </div>

</template>


<script>
import('@/assets/scss/styleguide.scss')
import(`@/assets/scss/base.scss`)
import LeaderBoard from "@/components/LeaderBoard.vue";
import AuditStatus from "@/components/AuditStatus.vue";
import ChartContract from "@/components/ChartContract.vue";
import ChartDynamic from "@/components/ChartDynamic.vue";


export default {
  name: 'FrontView',
  components: {
    LeaderBoard,
    AuditStatus,
    ChartDynamic,
    ChartContract
  },
  data() {
    return {
      statuses: []
    }
  },
  computed: {
    audits() {
      return this.$store.getters.audits?.filter(x => !x.rejected)
    }
  },
  mounted() {
  }

}
</script>
