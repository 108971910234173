<template>
  <ModalPlugin v-if="open_modal" :show_bottom="true">
    <template v-slot:header>
      <a href="" @click.prevent="open()">
        <i class="icon close"></i>
      </a>
      <div class="title-wrap">
        <strong class="title">Просмотр заявки</strong>
      </div>
    </template>
    <template v-slot:body>
      <div class="successful" v-if="audit.status === 'end'">
        <CheckIco class="icon"></CheckIco>
        <h2>Вы заработали </h2>
        <h2 class="point">{{
            Object.keys(audit_log).map(x => audit_log[x].get_point).reduce((partialSum, a) => partialSum + (a ? a : 0), 0)
          }} баллов</h2>
        <p>Договор был заключен {{ audit.logs.find(x => x.status === 'end').created_at }}</p>
      </div>
      <div class="person">
        <div class="name">{{ audit.name }}</div>
        <div class="info">{{ audit.info }}</div>
        <div class="point green-text" v-if="audit.status !== 'end'"><strong>{{
            Object.keys(audit_log).map(x => audit_log[x].get_point).reduce((partialSum, a) => partialSum + (a ? a : 0), 0)
          }}</strong>
          из {{
            Object.keys(audit_log).map(x => audit_log[x].point).reduce((partialSum, a) => partialSum + (a ? a : 0), 0)
          }} баллов
        </div>
      </div>

      <div class="audit-list">
        <ul class="audit-ul" v-if="audit_log">
          <template v-for="(status, key, order) in audit_log" :key="status">
            <li class="stage" v-if="!status.hide">
              <div :class="'name step'">
                <i v-if="status.state" :class="'icon block'"></i>
                <CheckIco class="icon" v-else-if="status.accept"></CheckIco>
                <i v-else-if="status.created_at && (status.wait&&status.last)" :class="'icon clock'"></i>

                <i v-else-if="status.created_at && status.last" :class="'num active'">{{ order + 1 }}</i>
                <i v-else :class="'num'">{{ order + 1 }}</i>
              </div>
              <div
                  :class="'info'+(status.created_at && status.active &&!(key==='client'&&!status.last)?' action':'')+(status.state && audit.rejected?' closed':'')">
                <div :class="'name' + (status.accept?' active':'')">
                  <span>{{
                      (status.created_at && !status.last) ? status.end : (status.state && audit.rejected) ? status.error : status.name
                    }}</span>
                  <div v-if="status.point" :class="'point'+(status.accept?' active':'')">+
                    {{ status.point }}
                  </div>
                </div>

                <div class="date" v-if="status.created_at">
                  {{ (status.state && audit.rejected) ? "Заявка закрыта" : status.created_at }}
                </div>
                <div class="client" v-if="key === 'client' && audit.audit_file">
                  <template v-if="status.last">
                    <p>
                      Ура, Аудит создан!<br>
                      Теперь его необходимо отправить клиенту и назначить встречу.
                      <a class="audit-file" @click="shareFile(audit.audit_file, 'Файл аудита')" :href="audit.audit_file">
                        <i class="icon pdf"></i>
                        <div class="file-name">
                          <span>Отправить клиенту</span>
                          <i class="icon chevron"></i>
                        </div>

                      </a>
                    </p>
                  </template>
                  <a v-else class="audit-file" @click="shareFile(audit.audit_file, 'Файл аудита')" :href="audit.audit_file">
                    <i class="icon attach"></i>
                    <div class="file-name">Аудит {{ audit.name }}</div>
                  </a>
                </div>
                <div class="meet" v-if="key === 'meet' && status.last && status.created_at">
                  <p>Если по результатам встречи будет направлено коммерческое предложение, то при заключении договора
                    вы
                    получите еще 100 баллов.</p>
                </div>
                <div class="client" v-if="key === 'deal' && audit.cp_file">
                  <template v-if="status.last">
                    <p>
                      Все готово для выхода на сделку!
                      <a class="audit-file" @click="shareFile(audit.cp_file, 'Коммерческое предложение')" :href="audit.cp_file">
                        <i class="icon pdf"></i>
                        <div class="file-name">
                          <span>Отправить клиенту</span>
                          <i class="icon chevron"></i>
                        </div>

                      </a>
                    </p>
                  </template>
                </div>
              </div>

            </li>
          </template>
        </ul>
      </div>
    </template>
    <template v-slot:bottom>
    </template>
  </ModalPlugin>
</template>
<script>
import detailMixins from "@/components/detailMixins";
import ModalPlugin from "@/components/ModalPlugin.vue";

export default {
  props: {
    audit_id: {
      type: Number
    },
    open: {
      type: Function
    }
  },
  mixins: [detailMixins],
  components: {
    ModalPlugin
  },
  data() {
    return {
      open_modal: true
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    shareFile(file_url, text) {
      if (navigator.share) {

        navigator.share({
          title: `Аудит ${this.audit.name}`,
          text: text,
          url: file_url,
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        return false;
      }
      else return file_url;
    }
  }
}
</script>