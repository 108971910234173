<script setup>

</script>

<template>
  <li>
    <div :class="'name'+(objects.length?' active':'')">
        <i :class="'num'+(objects.length?' active':'')">{{ order+1 }}</i>
      <span>{{ status.name }}</span>
    </div>
    <div class="list">
      <div class="info" v-if="objects.length && status.point && status_type !== 'end' && status.active">
        и получите <span class="green-text">{{ status.point * (objects.length) }} баллов за {{objects.length}} клиентов</span>
      </div>
      <template v-for="audit in objects" :key="audit.status">
        <AuditItem :audit="audit"></AuditItem>
      </template>

    </div>
  </li>
</template>

<script>
import AuditItem from "@/components/AuditItem.vue";

export default {
  props: {
    status: {
      type: Object
    },
    status_type: {
      type: String
    },
    audits: {
      type: Array
    },
    order: {
      type: Number,
      default: 0
    }
  },
  components: {
    AuditItem
  },
  computed: {
    objects() {
      return this.audits.filter(x => x.status === this.status_type)
    }
  }
}
</script>