<template>
  <div class="h90 d-flex">
    <div class="login">
      <div class="top">
       <img alt="Logo" title="Logo" class="logo-bank" :src="logo">
      </div>

      <form class="form form-signin" v-if="sended">
        <h2>Инструкции по восстановлению пароля отправлены на указанный email.</h2>

      </form>

      <form class="form form-signin" @submit="restore_password" v-else>
        <h2>Восстановление пароля</h2>
        <p>Введите email, на который был зарегистрирован личный кабинет</p>
        <div class="form-item">
          <label for="id_email">Email <span class="req">*</span></label>
          <input @keyup="removeError(user)" name="email" id="id_email" :class="user.username_error?'error':''"
                 v-model="user.username" required type="email" placeholder="email">
        </div>
        <div class="form-item">
          <button class="btn" :disabled="!user.username">Восстановить</button>
        </div>
      </form>
      <div class="action">
        <router-link to="/login">Вход</router-link>
      </div>
      <div class="bottom">
        <div class="text-wrapper-2">Powered by</div>
        <div class="logo-dr"><img alt="Logo DR" class="img" :src="publicPath+'LogoDr.svg'"/></div>
      </div>
    </div>
  </div>
</template>
<script>

  import dataMixin from "@/views/mixins";
  import {UserApi} from "@/api/user";

  export default {
  data() {
    return {
      user: {username: ""},
      publicPath: process.env.BASE_URL,
      sended: false,
      validate: {
        username: {
          func: (o) => {
            return !o.username.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
          }, error: 'Неверный формат email адреса'
        }
      }
    }
  },
  mixins: [dataMixin],
  mounted: function () {
  },
  methods: {
    restore_password: function (e) {
         e.preventDefault();
        let $this = this;
        if (!this.validateData(this.user)) {
          UserApi.restore_password(this.user.username).then(response => {

            if (response.data['errors']) {
              for (let i in response.data['errors']) {
                $this.$notify({
                  group: 'alert',
                  type: 'error ',
                  text: response.data['errors'][i]
                })
              }
            }
            else $this.sended = true;

          });

        }
      }
  }
}
</script>
<style scoped>

</style>