let dataMixin = {
    data() {
        return {
            publicPath: process.env.BASE_URL,
        }
    },
    computed: {
        logo() {
            return this.$store.getters.template?.logo || this.publicPath + 'dr.png'
        }
    },
    methods: {
        removeError(object) {
            Object.keys(object).forEach((key) => {
                if (key.endsWith('_error')) delete object[key]
            })
        },
        validateData: function (object) {
            let error = false;
            for (let field in this.validate) {
                let rule = this.validate[field];
                if (!rule.func) {
                    if (!object[field]) {
                        error = true;
                        object[`${field}_error`] = rule.error ? rule.error : 'Обязательное поле'
                    }
                } else {
                    if (rule.func(object)) {
                        error = true;
                        object[`${field}_error`] = rule.error ? rule.error : 'Обязательное поле'
                    } else
                        object[`${field}_error`] = false
                }
            }
            return error;
        },

        clickDate(event) {
            let position = event.target.getBoundingClientRect();
            let position_parent = document.getElementsByClassName('table dict')[0].getBoundingClientRect();
            let cal = document.getElementsByClassName('bootstrap-datetimepicker-widget')[0];
            cal.setAttribute('style', `top: ${parseInt(position.y - position_parent.y + 50)}px; left: ${parseInt(position.x - position_parent.x)}px;`)
        }
    }
};
export default dataMixin;
