<script setup>
import AuditItem from "@/components/AuditItem.vue";
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

const store = useStore()

const status_name = ref("")

const audits = computed(() => {
  return store.getters.audits.filter(x => {return status_name.value?x.status === status_name.value:x})
})
const statuses = computed(() => {
  return store.getters.statuses || {}
})

</script>

<template>
  <div class="filter">
    <div class="form">
      <div class="form-item select">
        <label for="id_status">Статус</label>
        <select v-model="status_name" name="" id="is_status">
          <option value="">Все</option>
          <option v-for="(status, key) in statuses" :key="key" :value="key">{{ status.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="audit-list archive">
    <AuditItem v-for="audit in audits" :key="audit.id" :audit="audit"></AuditItem>
  </div>
</template>

<style scoped>

</style>