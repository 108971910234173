<script setup>
import { onMounted, onUnmounted, defineProps } from 'vue'
import CreateAuditBTN from "@/components/CreateAuditBTN.vue";
defineProps({
  show_bottom: {
    type: Boolean,
    default: true
  }
})

onMounted(() => {
  document.body.classList.add('no-scroll')
})
onUnmounted(() => {
  document.body.classList.remove('no-scroll')
})
</script>

<template>
  <div class="modal-wrap">
    <div class="modal-overlay"></div>
    <div class="modal">
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
      <div class="bottom" v-if="show_bottom">
        <slot name="bottom"></slot>
        <CreateAuditBTN></CreateAuditBTN>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>