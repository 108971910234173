<script setup>

</script>

<template>
  <svg class="check-ico" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="check-circle">
      <circle id="Ellipse 92" cx="8.19995" cy="8" r="8" fill="#1B9940"/>
      <path id="Vector 61" d="M4.86658 8.00004L7.19991 10.3334L12.1999 5.33337" stroke="white" stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<style scoped>

</style>