<script setup>

import AuditDetail from "@/components/AuditDetail.vue";
</script>

<template>

  <div class="audit">
    <div @click="open_modal=true;" class="audit-name">
      <span>{{ audit.name }}</span>
      <i class="icon chevron"></i>
    </div>
    <div v-if="$store.getters.user.is_curator" class="user">
      <i class="icon user"></i>
      <span>{{audit.user_name}}</span>
    </div>

    <span class="error-text" v-if="audit.rejected">{{audit_log[audit.status]["error"]}}</span>
    <span class="green-text" v-if="audit.status === 'end'">Cделка заключена!</span>
    <div class="step" v-else>
      <template v-for="(value, key, index) in audit_log" :key="value.name">
        <div v-if="!value.hide" :class="`item`+ (!value.created_at||value.last?' next':'')">
          <i v-if="value.state" class="icon block"></i>
          <CheckIco v-else-if="value.created_at && value.accept"></CheckIco>
          <i v-else-if="value.created_at && value.wait && value.last" class="icon clock"></i>
          <i v-else-if="value.created_at && value.last" class="num green">{{ index + 1 }}</i>
          <i v-else class="num">{{ index + 1 }}</i>

          <template
              v-if="value.point">
            <div :class="'point'+(value.accept?' active':'')"> + {{ value.point }}</div>
            </template>
            <div class="date" v-if="value.created_at"> {{ value.created_at }}</div>

        </div>
      </template>
    </div>
  </div>
  <AuditDetail v-if="open_modal" :open="()=>open_modal=false" :audit_id="audit.id"></AuditDetail>
</template>
<script>
import detailMixins from "@/components/detailMixins";

export default {
  props: {
    audit: {
      type: Object
    }
  },
  mixins: [detailMixins],
  data() {
    return {
      open_modal: false
    }
  }
}
</script>