<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'

const store = useStore()

const menu_list = [
  {name: 'Заявки', path: {name: 'front'}, is_curator: false},
  {name: 'Баллы', path: {name: 'point'}, is_curator: false},
  {name: 'Архив', path: {name: 'archive'}, is_curator: false},
  {name: 'Правила', path: {name: 'rule'}, is_curator: false},

  {name: 'Дашбоард', path: {name: 'front'}, is_curator: true},
  {name: 'Заявки', path: {name: 'audit'}, is_curator: true},
  {name: 'Архив', path: {name: 'archive'}, is_curator: true},
  {name: 'Клиенты', path: {name: 'clients'}, is_curator: true},
]


const menus = computed(() => {
  return menu_list.filter(x => x.is_curator === store.getters.user.is_curator)
})


import CreateAuditBTN from "@/components/CreateAuditBTN.vue";

</script>

<template>
  <div class="menu-wrap">
    <CreateAuditBTN></CreateAuditBTN>

    <ul class="menu">
      <li v-for="(menu, index) in menus" :key="index">
        <router-link :to="menu.path" active-class="active">
          {{ menu.name }}
        </router-link>
      </li>

    </ul>
  </div>
</template>

<style scoped>

</style>