<script setup>

</script>

<template>
  <ul class="tab" v-if="range_checker">
    <li v-for="r in ranges" @click="range=r.value" :key="r.value"
        :class="r.value===range?'active':''">
      {{ r.name }}
    </li>
  </ul>
  <div class="month-range">
    <button class="outline small" @click="changeDate(prev)" :disabled="!prev">
      <i class="icon chevron left"></i>
    </button>
    <div class="name">{{ range === "year"?"":date.name }} {{date.year}}</div>
    <button class="outline small" @click="changeDate(next)" :disabled="!next">
      <i class="icon chevron"></i>
    </button>
  </div>
</template>

<script>

import moment from "moment";
require('moment/locale/ru');

export default {
  props: {
    range_checker: {
      type: Boolean,
      default: false
    },
    setValue: {
      type: Function
    },
  },
  data() {
    return {
      ranges: [
        {name: "Месяц", value: "month"},
        {name: "Год", value: "year"},
      ],
      range: "month",
      date: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        name: moment().month(new Date().getMonth()).format("MMMM")
      }

    }
  },
  watch:{
    range(){
      this.changeDate(this.date)
    }
  },
  computed: {
    prev() {
      let date = moment(`${this.date.year}-${this.date.month}-01`).subtract(1,`${this.range}s`).endOf(this.range)
      return {month: date.month()+1, year: date.year(), name: moment().month(date.month()).format("MMMM")}
    },
    next() {
      let date = moment(`${this.date.year}-${this.date.month}-01`).subtract(-1,`${this.range}s`).endOf(this.range),
          now = moment()
      return ((date.month() > now.month() && this.range === "month") && date.year() >= now.year())?null:{month: date.month()+1, year: date.year(), name: moment().month(date.month()).format("MMMM")}
    }
  },
  methods: {
    changeDate(value){
      this.date = value
      let val_date = JSON.parse(JSON.stringify(value))
      if (this.range !== "month") val_date.month = null
      this.setValue(val_date)
    }
  },
}
</script>