<template>
  <div class="h90 d-flex">
    <div class="login">
      <div class="top">
       <img alt="Logo" title="Logo" class="logo-bank" :src="logo">
      </div>
      <div class="card" v-if="errors.length">

        <div class="card-body text-center border border-danger" style="padding: 10px 20px">
          <h4 class="card-text mb-3" v-for="error in errors" :key="error">
            {{ error }}
          </h4>

        </div>

      </div>
      <div class="form form-signin" v-if="reg_end">

        <h2>
          Пароль успешно изменён
        </h2>
        <p>Можете авторизоваться в сервисе используя свой email и пароль</p>
        <small>
          Вы будете автоматически перенаправлены на вход через
          <strong>{{ currentTime }}</strong>
        </small>

      </div>
      <form class="form form-signin" @submit.prevent="change_password" v-if="!reg_end">
        <h2>Изменение пароля</h2>
        <div class="form-item">
          <label for="id_email">Новый пароль <span class="req">*</span></label>
          <input @keyup="removeError(password)" name="email" id="id_email"
                 :class="password.new_pasword_error?'error':''"
                 v-model="password.new_pasword" required :type="show_pass?'text':'password'" placeholder="Новый пароль">
          <i v-on:click="show_pass=!show_pass" :class="`icon ${show_pass?' eye-bold':'eye'}`"></i>
          <div class="help" v-if="password.new_pasword_error">{{ password.new_pasword_error }}</div>
        </div>
        <div class="form-item">
          <label for="id_email">Повторите пароль <span class="req">*</span></label>
          <input @keyup="removeError(password)" name="email" id="id_email"
                 :class="password.repeat_password_error?'error':''"
                 v-model="password.repeat_password" required :type="show_pass?'text':'password'"
                 placeholder="Повторите пароль">
          <i v-on:click="show_pass=!show_pass" :class="`icon ${show_pass?' eye-bold':'eye'}`"></i>
          <div class="help" v-if="password.repeat_password_error">{{ password.repeat_password_error }}</div>

        </div>
        <div class="form-item">
          <button class="btn" :disabled="!(password.new_pasword && password.repeat_password)">Восстановить пароль
          </button>
        </div>
      </form>

      <div class="bottom">
        <div class="text-wrapper-2">Powered by</div>
        <div class="logo-dr"><img alt="Logo DR" class="img" :src="publicPath+'LogoDr.svg'"/></div>
      </div>
    </div>
  </div>

</template>
<script>
import {UserApi} from '@/api/user'
import dataMixin from "@/views/mixins";

export default {
  name: 'SetPassword',
  mixins: [dataMixin],
  data() {
    return {
      password: {new_pasword: "", repeat_password: ""},
      show_pass: false,
      errors: [],
      can_change: true,
      currentTime: 5,
      timer: null,
      reg_end: false,
      validate: {
        new_pasword: {
          func: (o) => {
            return o.new_pasword !== o.repeat_password
          }, error: 'Введённые пароли не совпадают'
        }
      }
    }
  },

  unmounted() {
    this.stopTimer()
  },
  mounted: function () {
    let $this = this;
    UserApi.check_token(this.$route.params.uid, this.$route.params.token).then(response => {
      if (response.data['errors']) {
        $this.errors = response.data.errors;
      } else $this.can_change = true;
    });
  },
  methods: {
    change_password: function (e) {
      e.preventDefault();
      let $this = this;
      if (!this.validateData(this.password)) {
        let data = {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          password: this.password.new_pasword
        }
        UserApi.change_password(data).then(response => {
          if (response.data['errors']) {
            for (let i in response.data['errors']) {
              $this.$notify({
                group: 'alert',
                type: 'error ',
                text: response.data['errors'][i]
              })
            }
          } else {
            $this.reg_end = true;
            this.startTimer();
          }
        });

      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer();
        this.$router.push({name: "login"});
      }
    }
  },
}
</script>

