<template>
  <div class="chart">
    <h1>Ежемесячная динамика</h1>
    <MonthRange :setValue="setValue" :value="date"></MonthRange>
    <div style="height: 250px; width: 100%;">
      <div id="legend" class="legend"></div>
      <Bar
          id="my-chart-id"
          v-if="chartData"
          :options="chartOptions"
          :data="chartData"
          :plugins="[htmlLegendPlugin]"
      />
    </div>
  </div>
</template>


<script>
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {AuditApi} from "@/api/banker";
import htmlLegendPlugin from "@/components/legendPlugin";
import MonthRange from "@/components/MonthRange.vue";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

ChartJS.defaults.plugins.legend.display = false;

export default {
  components: {
    Bar, MonthRange
  },
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      date: {},
      styles: {
        meet: {
          name: "Отправлено аудитов",
          color: "#ED854E",
        },
        cp: {
          name: "Проведено встреч",
          color: "#77A1F2",
        },
        deal: {
          name: "Отправлено КП",
          color: "#04BDB2",
        },
        end: {
          name: "Заключено договоров",
          color: "#1B9940",
        },
      },
      data: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x:
              {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  // autoSkip: false,
                  font: {
                    size: 9,
                  }
                },
              },
          y:
              {
                stacked: true,
                display: false,
              },

        },
      }
    }
  },
  mounted() {
    this.getData()
  },
  watch:{
    date(){
      this.getData()
    }
  },
  methods: {
    getData() {
      AuditApi.audit_dynamic(this.date.month||"", this.date.year||"").then(response => this.data = response)
    },
    setValue(val){
      this.date = val
    }
  },
  computed: {
    chartData() {
      if (this.data) {
        let datasets = {
          labels: this.data.labels,
          datasets: []
        }
        Object.keys(this.data.data).forEach(key => {
          let name = this.styles[key]
          datasets.datasets.push(
              {
                label: name.name,
                backgroundColor: name.color,

                barThickness: 24,
                data: this.data.data[key]
              }
          )
        })
        return datasets
      }
      return null
    }
  }

}
</script>

